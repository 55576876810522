<template>
  <div>
    <h1 class="page-header">{{ $t("menu.buyCarrots") }} <small class="text-warning"
                                                               v-if="!livePayments && this.isAdmin">Paypal
      Test</small>
    </h1>
    <div class="alert alert-warning" v-if="!areUserDataFilled && this.allowBuying">
      <strong>{{ $t("common.warning") }}</strong>
      {{ $t("buyCarrots.w1") }}
      <router-link to="/my-profile">
        {{ $t("buyCarrots.w2") }}
      </router-link>
      {{ $t("buyCarrots.w3") }} {{ $t("buyCarrots.notPossibleWithoutProfileData") }}
    </div>
    <hr/>
    <div class="row justify-content-center">
      <div
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 m-l-5 m-r-5 m-b-10 m-t-20 p-l-10 p-d-10"
      >
        <div class="form-group">
          <label class="col-md-12 col-form-label"
          >{{ $t("buyCarrots.numberOfCarrots") }}: <strong>{{ this.priceInfo.numCarrots }}</strong>
            <span v-if="!isEmptyState" :class="carrotTextClass"
            >(1 {{ $t("common.carrot") }} = {{ this.carrotPrice }}
              {{ this.priceInfo.currency }})</span
            ></label
          >
          <form class="col-md-12">
            <vue-slider v-if="isEmptyState" disabled="disabled"></vue-slider>
            <vue-slider
                v-else
                class="m-b-35"
                v-model="priceInfo.numCarrots"
                :min="this.priceInfo.minCarrots"
                :max="this.priceInfo.maxCarrots"
                :marks="marks"
                :process="this.processSlider"
            ></vue-slider>
          </form>
        </div>
        <div v-if="this.priceInfo.arePricesTaxable">
          <div class="form-group row rm-m-b-0-5-rem">
            <div class="col-md-7 col-xs-7">
              <h5>{{ $t("buyCarrots.priceWithoutTax") }}:</h5>
            </div>
            <div class="col-md-5 col-xs-5 text-right">
              <h5>{{ this.priceWithoutTax }} {{ this.priceInfo.currency }}</h5>
            </div>
          </div>
          <div class="form-group row rm-m-b-0-5-rem">
            <div class="col-md-7 col-xs-7">
              <h5 v-if="isEmptyState">{{ $t("buyCarrots.tax") }}:</h5>
              <h5 v-else>{{ $t("buyCarrots.tax") }} ({{ this.priceInfo.taxPercent }}%):</h5>
            </div>
            <div class="col-md-5 col-xs-5 text-right">
              <h5>{{ this.tax }} {{ this.priceInfo.currency }}</h5>
            </div>
          </div>
          <div class="form-group row rm-m-b-0-5-rem">
            <div class="col-md-7 col-xs-7">
              <h5><b>{{ $t("buyCarrots.finalPrice") }}:</b></h5>
            </div>
            <div class="col-md-5 col-xs-5 text-right">
              <h5>
                <b>{{ this.finalPrice }} {{ this.priceInfo.currency }}</b>
              </h5>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="form-group row rm-m-b-0-5-rem">
            <div class="col-md-7 col-xs-7">
              <h5><b>{{ $t("buyCarrots.priceWithoutTax") }}:</b></h5>
            </div>
            <div class="col-md-5 col-xs-5 text-right">
              <h5>
                <b>{{ this.priceWithoutTax }} {{ this.priceInfo.currency }}</b>
              </h5>
            </div>
          </div>
        </div>
        <hr/>
        <div class="row justify-content-center">
          <div class="col-md-4"></div>
          <div id="paypal-checkout" class="col-md-4"></div>
          <div class="col-md-4"></div>
        </div>
        <carrot-calculator
            :num-carrots="numCarrotsInt"
            :carrot-price="this.carrotPrice"
            :is-empty-state="isEmptyState"
        ></carrot-calculator>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import CarrotCalculator from "@/components/profile/CarrotCalculator";
import {userService} from "@/_services";
import {pricesService} from "@/_services/prices.service";
import {paymentsService} from "@/_services/payments.service";
import {showSweetAlert} from "@/_helpers/api";
import {handleError} from "@/_helpers/api";
import router from "@/config/PageRoutes";
import {isUserAdmin} from "@/_helpers";

export default {
  name: "CreditBuy",
  components: {
    CarrotCalculator,
  },
  data() {
    return {
      // App info
      priceInfo: {
        minCarrots: 0,
        maxCarrots: 0,
        currency: "",
        taxPercent: 0,
        arePricesTaxable: false,
        groups: [],
        numCarrots: 0,
      },
      userData: {
        street: "",
        postNumber: "",
        post: "",
        country: "",
      },
      livePayments: false
    };
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
    payPalLocale() {
      if (this.$i18n.locale === "de") {
        return "de_DE";
      }

      if (this.$i18n.locale === "us") {
        return "en_US";
      }

      return "sl_SI";
    },
    marks() {
      let marks = {};

      for (let i = 0; i < this.priceInfo.groups.length; i++) {
        let group = this.priceInfo.groups[i];
        marks[group.minCarrots] = {
          "label": group.minCarrots
        }
      }

      let lastGroup = this.priceInfo.groups[this.priceInfo.groups.length - 1];
      marks[lastGroup.maxCarrots] = {
        "label": lastGroup.maxCarrots
      }

      return marks;
    },
    allowBuying() {
      if (this.isAdmin) {
        return true;
      }

      return this.livePayments;
    },
    isAdmin() {
      return isUserAdmin();
    },
    isEmptyState() {
      return (
          this.priceInfo === null ||
          this.priceInfo === {} ||
          this.priceInfo.maxCarrots === 0 ||
          this.priceInfo.currency === "" ||
          this.priceInfo.taxPercent === 0
      );
    },
    carrotTextClass() {
      let baseClass = "m-l-4"
      if (this.activeGroup !== null) {
        baseClass += " text-" + this.activeGroup.group.color;
      }
      return baseClass;
    },
    activeGroup() {
      if (this.isEmptyState) {
        return null;
      }

      for (let i = 0; i < this.priceInfo.groups.length; i++) {
        let g = this.priceInfo.groups[i];
        if (this.numCarrotsInt >= g.minCarrots && this.numCarrotsInt <= g.maxCarrots) {
          return {group: g, index: i};
        }
      }

      return null;
    },
    carrotPrice() {
      if (this.isEmptyState) {
        return 0;
      }

      return this.activeGroup.group.carrotPrice;
    },
    numCarrotsInt() {
      if (this.isEmptyState) {
        return 0;
      }

      const intNum = parseInt(this.priceInfo.numCarrots);
      if (isNaN(intNum) || intNum < 0) {
        return 0;
      }
      return intNum;
    },
    priceWithoutTax() {
      if (this.isEmptyState) {
        return 0.0;
      }

      let pwt = this.numCarrotsInt * this.carrotPrice;
      return parseFloat(parseFloat(pwt).toFixed(2));
    },
    tax() {
      if (this.isEmptyState) {
        return 0.0;
      }

      let t = (
          this.priceWithoutTax *
          (this.priceInfo.taxPercent / 100)
      ).toFixed(2);
      return parseFloat(t);
    },
    finalPrice() {
      if (this.isEmptyState) {
        return 0.0;
      }

      // Multiply by 1.0 just to be sure to get float
      let fp = (this.priceWithoutTax + this.tax).toFixed(2);
      return parseFloat(fp);
    },
    areUserDataFilled() {
      if (this.userData === undefined || this.userData === {})
        return false;

      let streetFilled = "street" in this.userData && this.userData.street != null && this.userData.street !== "" && this.userData.street.length >= 3;
      let postNumberFilled = "postNumber" in this.userData && this.userData.postNumber != null && this.userData.postNumber !== "";
      let postFilled = "post" in this.userData && this.userData.post != null && this.userData.post !== "" && this.userData.post.length >= 3;
      let countryFilled = "country" in this.userData && this.userData.country != null && this.userData.country !== "";

      return streetFilled && postNumberFilled && postFilled && countryFilled;
    },
  },
  methods: {
    processSlider() {
      if (this.priceInfo.groups.length === 0) {
        return []
      }

      let minNumber = this.priceInfo.groups[0].minCarrots

      let arr = []
      for (let i = 0; i < this.priceInfo.groups.length; i++) {
        let g = this.priceInfo.groups[i];
        if (i < this.priceInfo.groups.length - 1) {
          arr.push([g.minCarrots - minNumber, g.maxCarrots - (minNumber - 1), {"backgroundColor": g.color}]);
        } else {
          arr.push([g.minCarrots - minNumber, g.maxCarrots - minNumber, {"backgroundColor": g.color}]);
        }

      }
      return arr
    },
    createPayPalOrder(...[,]) {
      const postData = this.prepareCreateIntentData();
      return paymentsService.createPaymentIntent(postData).catch((error) => {
        handleError(error, router, this);
      });
    },
    approvePayPalOrder(data) {
      showSweetAlert("I-PAYMENT-IN_PROGRESS", this);
      const postData = this.prepareApprovePaymentData(data);
      return paymentsService
          .onApprovePayment(postData)
          .catch((error) => {
            handleError(error, router, this);
          })
          .then((result) => {
            if (result === undefined) {
              // In case of an error which is handled above
              return;
            }

            if ("message" in result) {
              showSweetAlert(result.message, this);
            }
            if ("credit" in result) {
              this.$store.commit("account/updateCredit", result.credit);
            }
          });
    },
    configurePayPal() {
      // eslint-disable-next-line no-undef
      paypal
          .Buttons({
            style: {
              height: 35,
            },
            createOrder: this.createPayPalOrder,
            onApprove: this.approvePayPalOrder,
          })
          .render("#paypal-checkout");
    },
    includeScript(URL, callback) {
      let documentTag = document,
          tag = "script",
          object = documentTag.createElement(tag),
          scriptTag = documentTag.getElementsByTagName(tag)[0];
      object.src = "https://" + URL;
      if (callback) {
        object.addEventListener(
            "load",
            function (e) {
              callback(null, e);
            },
            false
        );
      }
      scriptTag.parentNode.insertBefore(object, scriptTag);
    },
    prepareCreateIntentData() {
      return {
        numCarrots: this.numCarrotsInt,
        groupIndex: this.activeGroup.index,
        minCarrots: this.activeGroup.group.minCarrots,
        maxCarrots: this.activeGroup.group.maxCarrots,
        carrotPrice: this.carrotPrice,
        currency: this.priceInfo.currency,
        taxPercent: this.priceInfo.taxPercent,
        arePricesTaxable: this.priceInfo.arePricesTaxable,
      };
    },
    prepareApprovePaymentData(data) {
      return {
        orderID: data.orderID,
      };
    },
  },
  created() {
    // Load carrots data from server
    pricesService.get().then((data) => {
      this.priceInfo = {
        numCarrots: data.defaultNumCarrots,
        minCarrots: data.groups[0].minCarrots,
        maxCarrots: data.maxCarrots,
        currency: data.currency,
        taxPercent: data.taxPercent,
        arePricesTaxable: data.arePricesTaxable,
        groups: data.groups,
      };

      this.livePayments = data.livePayments;

      userService.get().then(user => {
        if (user.street !== undefined) {
          this.userData.street = user.street
        }

        if (user.postNumber !== undefined) {
          this.userData.postNumber = user.postNumber;
        }

        if (user.post !== undefined) {
          this.userData.post = user.post;
        }

        if (user.country !== undefined) {
          this.userData.country = user.country;
        }

        if (!this.areUserDataFilled || !this.allowBuying) {
          return;
        }

        let payPalClientId = process.env.VUE_APP_PAYPAL_SANDBOX_CLIENT_ID;
        if (this.livePayments) {
          payPalClientId = process.env.VUE_APP_PAYPAL_LIVE_CLIENT_ID;
        }

        this.includeScript(
            "www.paypal.com/sdk/js?client-id=" + payPalClientId + "&locale=" + this.payPalLocale + "&currency=EUR",
            function () {
              this.configurePayPal();
            }.bind(this)
        );
      })
    });
  },
  mounted() {

  },
};
</script>

<style scoped></style>
