<template>
  <div>
    <hr/>
    <div class="row justify-content-center m-b-5">
      <h5 class="justify-content-center" v-if="isEmptyState">{{ $t("buyCarrots.howManyGamesCanIPlayNone") }}</h5>
      <h5 class="justify-content-center" v-else>{{ $t("buyCarrots.howManyGamesCanIPlay") }} {{ numCarrotsPrepend }}
        <span
            class="font-weight-bold">{{ numCarrots }}</span>
        {{ $t("common.carrots") }}{{ $t("buyCarrots.howManyGamesCanIPlaySuffix") }}?</h5>
    </div>
    <div class="row justify-content-center">
      <h6><b>{{ $t("games.education") }}</b></h6>
    </div>
    <div class="row justify-content-center m-b-10">
      <carrot-calculator-game-info
          v-for="(gameInfo, idx) in educationGames"
          :key="idx"
          :game-info="gameInfo"
          :game="idx"
          :num-carrots="numCarrots"
          :carrot-price="carrotPrice"
          :is-empty-state="isEmptyState"
      ></carrot-calculator-game-info>
    </div>
    <div class="row justify-content-center">
      <h6><b>{{ $t("games.entertainment") }}</b></h6>
    </div>
    <div class="row justify-content-center">
      <carrot-calculator-game-info
          v-for="(gameInfo, idx) in entertainmentGames"
          :key="idx"
          :game-info="gameInfo"
          :game="idx"
          :num-carrots="numCarrots"
          :carrot-price="carrotPrice"
          :is-empty-state="isEmptyState"
      ></carrot-calculator-game-info>
    </div>
  </div>
</template>

<script>
import {snakeCase} from "lodash";
import {getEducationalGames, getEntertainmentGames} from "@/_helpers/games_helper";
import {gamesService} from "@/_services";
import {handleError} from "@/_helpers/api";
import router from "@/config/PageRoutes";
import CarrotCalculatorGameInfo from "@/components/profile/CarrotCalculatorGameInfo";

export default {
  name: "CarrotCalculator",
  components: {CarrotCalculatorGameInfo},
  data() {
    return {
      gameInfoLoading: false,
      gameInfo: {},
      zPrep: [18, 19, 21, 22, 28, 29, 31, 32, 38, 39, 41, 42, 48, 49, 51, 52, 58, 59, 61, 62, 68, 69, 71, 72, 78, 79, 81, 82, 88, 89, 91, 92, 98, 99],
    }
  },
  props: {
    numCarrots: {
      type: Number,
      required: true
    },
    carrotPrice: {
      type: Number,
      required: true
    },
    isEmptyState: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    educationGames() {
      let gi = {...this.gameInfo}
      if (gi === {}) {
        return {};
      }

      const games = getEducationalGames();
      // TODO: Sort out this snake_case to camelCase shit!
      const snakeGameIDs = Object.keys(games).map(snakeCase);
      let gameInfoKeys = Object.keys(gi);
      for (let index = 0; index < gameInfoKeys.length; ++index) {
        if (!snakeGameIDs.includes(gameInfoKeys[index])) {
          delete gi[gameInfoKeys[index]];
        }
      }

      return gi;
    },
    entertainmentGames() {
      let gi = {...this.gameInfo}
      if (gi === {}) {
        return {};
      }

      const games = getEntertainmentGames();
      // TODO: Sort out this snake_case to camelCase shit!
      const snakeGameIDs = Object.keys(games).map(snakeCase);
      let gameInfoKeys = Object.keys(gi);
      for (let index = 0; index < gameInfoKeys.length; ++index) {
        if (!snakeGameIDs.includes(gameInfoKeys[index])) {
          delete gi[gameInfoKeys[index]];
        }
      }

      return gi;
    },
    numCarrotsPrepend() {
      if (this.$i18n.locale === "de") {
        return "mit";
      }

      if (this.$i18n.locale === "us") {
        return "with";
      }

      if (this.zPrep.includes(this.numCarrots)) {
        return "z";
      }

      return "s";
    }
  },
  methods: {
    initGame() {
      this.gameInfo = {};
      this.gameInfoLoading = true;

      gamesService.get_info("all").then(
          data => {
            this.gameInfo = data;
            this.gameInfoLoading = false;
          }
      ).catch(error => {
        this.gameInfoLoading = false;
        handleError(error, router)
      });
    }
  },
  created() {
    this.initGame();
  },
}
</script>

<style scoped>

</style>
