import {
  getPaymentIntentUrl,
  getPaymentIntentMethod,
  getPaymentApproveMethod,
  getPaymentApproveUrl,
  getListPaymentsMethod,
  getListPaymentsUrl,
} from "@/_helpers/payments_helper";
import { fetcher, handleResponse } from "@/_helpers/api";

export const paymentsService = {
  createPaymentIntent,
  onApprovePayment,
  listPayments,
};

function createPaymentIntent(data) {
  let requestOptions = {
    method: getPaymentIntentMethod(),
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetcher(getPaymentIntentUrl(), requestOptions).then(handleResponse);
}

function onApprovePayment(data) {
  let requestOptions = {
    method: getPaymentApproveMethod(),
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetcher(getPaymentApproveUrl(), requestOptions).then(handleResponse);
}

function listPayments() {
  let requestOptions = {
    method: getListPaymentsMethod(),
    headers: { "Content-Type": "application/json" },
  };

  return fetcher(getListPaymentsUrl(), requestOptions).then(handleResponse);
}
