import { getPricesUrl, getPricesMethod } from "@/_helpers/prices_helper";
import { fetcher, handleResponse } from "@/_helpers/api";

export const pricesService = {
  get,
};

function get() {
  let requestOptions = {
    method: getPricesMethod(),
    headers: { "Content-Type": "application/json" },
  };

  return fetcher(getPricesUrl(), requestOptions).then(handleResponse);
}
