import { config } from "@/config/config";

export function getPaymentIntentUrl() {
  return `${config.apiUrl}${config.urls.basePayments}/${config.payments.intent.url}`;
}

export function getPaymentIntentMethod() {
  return config.payments.intent.method;
}

export function getPaymentApproveUrl() {
  return `${config.apiUrl}${config.urls.basePayments}/${config.payments.approve.url}`;
}

export function getPaymentApproveMethod() {
  return config.payments.approve.method;
}

export function getListPaymentsUrl() {
  return `${config.apiUrl}${config.urls.basePayments}/${config.payments.list.url}`;
}

export function getListPaymentsMethod() {
  return config.payments.list.method;
}
